export default [
    {
        path: '/readingRoom',
        name: 'readingRoom',
        component: () => import('@/views/readingRoom/ReadingRoom.vue'),
    },
    {
        path: '/Details',
        name: 'Details',
        component: () => import('@/views/readingRoom/Details.vue'),
        // component: () => import('@views/readingRoom/Details.vue')
    },
];
