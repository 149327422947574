<template>
    <!-- <div class="breadcrumb"></div> -->
     <el-breadcrumb class="breadcrumb" v-if="nav.length > 0">
       <el-breadcrumb-item>当前位置：</el-breadcrumb-item>
       <el-breadcrumb-item v-for="(item,index) in nav" :key="index" :to="(index+1) === nav.length ? '' : item.to">{{ item.title }}</el-breadcrumb-item>
     </el-breadcrumb>
</template>

<script>
export default {
    props: {
        nav: {
            type: Array,
            default() {
                return [];
            },
        },
    },
};
</script>

<style scoped lang="less">
.breadcrumb {
    //padding: 29px 0;	
	line-height: 2;
	margin-bottom: 10px;
}

::v-deep .el-breadcrumb__inner {
    cursor: pointer;
}

//::v-deep .el-breadcrumb__separator{
//  margin: 0;
//}
</style>
