export default [
    {
        path: '/recommended',
        name: 'recommended',
        component: () => import('@/views/recommended/index.vue'),
    },
    {
        path: '/recommended/skillDetail',
        name: 'skillDetail',
        component: () => import('@/views/recommended/skillDetail.vue'),
    },
    {
        path: '/recommended/duhaoshu',
        name: 'duhaoshu',
        component: () => import('@/views/recommended/duhaoshu.vue'),
    },
    {
        path: '/recommended/enjoyActivities',
        name: 'enjoyActivities',
        component: () => import('@/views/recommended/enjoyActivities.vue'),
    },
    {
        path: '/recommended/enjoyActivitiesDetail',
        name: 'enjoyActivitiesDetail',
        component: () => import('@/views/recommended/enjoyActivitiesDetail.vue'),
    },
    {
        path: '/recommended/learnTalent',
        name: 'learnTalent',
        component: () => import('@/views/recommended/learnTalent.vue'),
    },
    {
        path: '/recommended/learnTalentDetail',
        name: 'learnTalentDetail',
        component: () => import('@/views/recommended/learnTalentDetail.vue'),
    },
];
