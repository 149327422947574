//关于本馆
export default [
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/about/about.vue'),
    },
    {
        path: '/leader',
        name: 'leader',
        component: () => import('@/views/about/leader.vue'),
    },
    {
        path: '/organization',
        name: 'organization',
        component: () => import('@/views/about/organization.vue'),
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('@/views/about/contact.vue'),
    },
];
