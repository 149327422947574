import { _delete, get, post, put } from '@/utils/request.js';

//获取首页轮播图/dev-api/system/slideshow/list
export function getSlideshowList() {
    return get({
        url: '/system/slideshow/list',
        token: true,
    });
}

//获取首页咨询动态列表/dev-api/system/consulting/list
export function getConsultingList(data) {
    return get({
        url: '/system/consulting/list',
        data,
    });
}

//获取首页咨询动态详情/dev-api/system/consulting/{id}
export function getConsultingDetail(id) {
    return get({
        url: `/system/consulting/${id}`,
        token: true,
    });
}
//获取首页资讯详情/dev-api/system/consulting/{id}
export function getConsultingDetails(id) {
    return get({
        url: `/system/consulting/${id}`,
        token: true,
    });
}
//获取首页精彩推荐列表/dev-api/system/activity/list
export function getActivityList(data) {
    return get({
        url: '/system/activity/thirdparty/list',
        data,
    });
}

//获取首页文化活动列表system/training/list
export function getTrainingList(data) {
    return get({
        url: '/system/training/thirdparty/list',
        data,
    });
}

//获取活动详情/dev-api/system/activity/{id}
export function getActivityDetails(id) {
    return get({
        url: `/system/activity/${id}`,
        token: true,
    });
}

//活动报名system/userActivity
export function userActivity(data) {
    return post({
        url: '/system/userActivity',
        data,
        token: true,
    });
}

//获取首页璀璨空间列表system/brightspace/list
export function getBrightspaceList(data) {
    return get({
        url: '/system/brightspace/list',
        data,
        token: true,
    });
}

//获取首页璀璨空间详情system/brightspace/{id}
export function getBrightspaceDetails(id) {
    return get({
        url: `/system/brightspace/${id}`,
        token: true,
    });
}

//获取课程培训详情/dev-api/system/activity/{id}
export function getTrainingDetails(id) {
    return get({
        url: `/system/training/${id}`,
        token: true,
    });
}
//课程培训报名system/userCourseTraining
export function userCourseTraining(data) {
    return post({
        url: '/system/userCourseTraining',
        data,
        token: true,
    });
}
//取消课程培训报名system/userCourseTraining//{id}
export function delUserCourseTraining(id) {
    return _delete({
        url: `/system/userCourseTraining/${id}`,
        token: true,
    });
}
//获取举办场馆
export function getLibraryList(data) {
    return get({
        url: 'system/library/getLibrarys',
        data,
        loading: true,
    });
}

//获取定位system/library/getLibraryPosition
export function getLibraryPosition(data) {
    return get({
        url: 'system/library/getLibraryPosition',
        token: true,
        data,
    });
}

//非遗文化目录列表/dev-api/system/heritageActivity/list
export function getHeritageActivityList(data) {
    return get({
        url: '/system/heritageActivity/list',
        data,
        token: true,
    });
}

//非遗文化目录详情/dev-api/system/heritageActivity/{id}
export function getHeritageActivityDetails(id) {
    return get({
        url: `/system/heritageActivity/${id}`,
        token: true,
    });
}

//查询政策列表/dev-api/system/policy/list
export function getPolicyList(data) {
    return get({
        url: '/system/policy/list',
        data,
        token: true,
    });
}

//查询政策详情/dev-api/system/policy/{id}
export function getPolicyDetails(id) {
    return get({
        url: `/system/policy/${id}`,
        token: true,
    });
}

//查询传承人列表/dev-api/system/inheritor/list
export function getInheritorList(data) {
    return get({
        url: '/system/inheritor/list',
        data,
        token: true,
    });
}

//查询传承人详情/dev-api/system/inheritor/{id}
export function getInheritorDetails(id) {
    return get({
        url: `/system/inheritor/${id}`,
        token: true,
    });
}

//查询非遗名录列表/dev-api/system/heritage/list
export function getHeritageList(data) {
    return get({
        url: '/system/heritage/list',
        data,
        token: true,
    });
}

//查询非遗名录详情/dev-api/system/heritage/{id}
export function getHeritageDetails(id) {
    return get({
        url: `/system/heritage/${id}`,
        token: true,
    });
}

//获取商品列表/dev-api/system/goods/list
export function getGoodsList(data) {
    return get({
        url: '/system/goods/list',
        data,
        token: true,
    });
}

//获取商品详情/dev-api/system/goods/{id}
export function getGoodsDetails(id) {
    return get({
        url: `/system/goods/${id}`,
        token: true,
    });
}

//获取商品分类列表/dev-api/system/classification/list
export function getClassificationList(data) {
    return get({
        url: '/system/classification/list',
        data,
        token: true,
    });
}

//获取收获地址列表/dev-api/system/address/list
export function getAddressList(data) {
    return get({
        url: '/system/address/list',
        data,
        token: true,
    });
}

//设置用户默认收获地址/dev-api/system/address/set
export function setAddressSet(data) {
    return put({
        url: '/system/address/set',
        data,
        token: true,
    });
}
//修改收获地址/dev-api/system/address
export function setAddress(data) {
    return put({
        url: '/system/address',
        data,
        token: true,
    });
}

//新增收货地址/dev-api/system/address
export function addAddress(data) {
    return post({
        url: '/system/address',
        data,
        token: true,
    });
}

//新增订单/dev-api/system/order
export function addOrder(data) {
    return post({
        url: '/system/order',
        data,
        token: true,
    });
}

//新增订单/dev-api/system/order
export function updateOrder(data) {
    return put({
        url: '/system/order',
        data,
        token: true,
    });
}

//获取订单列表/dev-api/system/order/list
export function getOrderList(data) {
    return get({
        url: '/system/order/list',
        data,
        token: true,
    });
}

//获取订单详情/dev-api/system/order/{id}
export function getOrderDetails(id) {
    return get({
        url: `/system/order/${id}`,
        token: true,
    });
}

//查询用户收藏点赞列表/dev-api/system/praiseCollection/list
// 类型：1.收藏活动;2.收藏预约;3.收藏课程;4.收藏帖子;5.点赞活动;6.点赞预约;7.点赞课程;8.点赞帖子
export function getPraiseCollectionList(data) {
    return get({
        url: '/system/praiseCollection/list',
        data,
        token: true,
    });
}

//修改用户信息
export function updateUserInfo(data) {
    return put({
        url: 'system/user/updateUser',
        data,
        token: true,
        loading: true,
    });
}

// TODO；获取用户信息
export function getUserInfo(data) {
    return get({
        url: '/getUserInfo',
        data,
        token: true,
    });
}

//登录/dev-api/pcLogin
export function login(data) {
    return post({
        url: '/pcLogin',
        data,
    });
}

//登出/dev-api/logout
export function logout(data) {
    return post({
        url: '/logout',
        data,
    });
}

//总分馆列表/prod-api/system/library/getLibrarys
export function getLibrarys(data) {
    return get({
        url: '/system/library/getLibrarys',
        data,
        token: true,
    });
}

//获取总分馆详情/dev-api/system/library/{id}
export function getLibraryDetails(id) {
    return get({
        url: `/system/library/${id}`,
        token: true,
    });
}

//获取招募着详情/dev-api/system/recruit/{id}
export function getRecruitDetails(id) {
    return get({
        url: `/system/recruit/${id}`,
        token: true,
    });
}

//TODO；新增
export function addActivity(data) {
    return post({
        url: 'system/userActivity',
        data,
        token: true,
    });
}
//TODO：删除报名
export function delActivity(data) {
    return _delete({
        url: 'system/userActivity/' + data,
        token: true,
    });
}

//获取场馆预约场次/prod-api/system/session/list
export function getSessionList(data) {
    return get({
        url: '/system/session/list',
        data,
        token: true,
    });
}
//获取图书馆书本列表/dev-api/system/books/list
export function getBooksList(data) {
    return get({
        url: '/system/bookroom/list',
        data,
        token: true,
    });
}

//获取图书详情/dev-api/system/books/{id}
export function getBooksDetails(id) {
    return get({
        url: `/system/bookroom/${id}`,
        token: true,
    });
}
//获取文明创作详情/prod-api/system/creation/{id}
export function getCreationDetails(id) {
    return get({
        url: `/system/creation/${id}`,
        token: true,
    });
}

//获取验证码/dev-api/send/{mobile}
export function getSendCode(mobile) {
    return get({
        url: `/send/${mobile}`,
    });
}

//我的消息列表system/notice/list
export function getNoticeList(data) {
    return get({
        url: '/system/notice/list',
        data,
        token: true,
    });
}

//获取消息详情system/notice/{id}
export function getNoticeDetails(id) {
    return get({
        url: `/system/notice/${id}`,
        token: true,
    });
}

//个人收藏列表/system/user/getCollect
export function getCollectList(data) {
    return get({
        url: '/system/user/getCollect',
        data,
        token: true,
    });
}

//学习记录列表/system/learnRecored/userLearns
export function getLearnRecoredList(data) {
    return get({
        url: '/system/learnRecored/userLearns',
        data,
        token: true,
    });
}
//疑难解答列表/prod-api/system/troubleshooting/list?pageSize=9999
export function getTroubleshootingList(data) {
    return get({
        url: '/system/troubleshooting/list',
        data,
        token: true,
    });
}
//意见反馈/prod-api/system/feedback
export function addFeedback(data) {
    return post({
        url: '/system/feedback',
        data,
        token: true,
    });
}
//场馆详情/prod-api/system/library/19
export function getLibraryDetail(id) {
    return get({
        url: `/system/library/${id}`,
        token: true,
    });
}

//获取社群数量https://whg.admin.ltzsgl.com/prod-api/system/user/getCommunityAndPostsCount
export function getCommunityAndPostsCount() {
    return get({
        url: '/system/user/getCommunityAndPostsCount',
        token: true,
    });
}
//我要参加列表/system/userdesire/getList
export function getDesireList(data) {
    return get({
        url: '/system/userdesire/getList',
        data,
        token: true,
    });
}

///dev-api/system/libraryOtherInfo/getList
export function getLibraryOtherInfoList(data) {
    return get({
        url: '/system/libraryOtherInfo/getList',
        token: true,
        data,
    });
}

//积分记录/dev-api/system/record/list
export function getRecordList(data) {
    return get({
        url: '/system/record/myList',
        data,
        token: true,
    });
}

// 获取VR列表
export function getVrList(data) {
    return get({
        url: '/system/vr/list',
        data,
        token: true,
    });
}

// 获取VR详情
export function getVrDetail(id) {
    return get({
        url: `/system/vr/${id}`,
        token: true,
    });
}
