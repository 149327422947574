//非遗文化
export default [
    {
        path: '/nonHeritage',
        name: 'nonHeritage',
        component: () => import('@/views/nonHeritage/index.vue'),
    },
    {
        path: '/nonHeritage/policy',
        name: 'nonHeritagePolicy',
        component: () => import('@/views/nonHeritage/policy.vue'),
    },
    {
        path: '/nonHeritage/inherit',
        name: 'nonHeritageInherit',
        component: () => import('@/views/nonHeritage/inherit.vue'),
    },
    {
        path: '/nonHeritage/activity',
        name: 'nonHeritageActivity',
        component: () => import('@/views/nonHeritage/activity.vue'),
    },
    {
        path: '/nonHeritage/detail',
        name: 'nonHeritageDetail',
        component: () => import('@/views/nonHeritage/detail.vue'),
    },
];
