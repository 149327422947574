<template>
    <div class="good-list-global-item">
        <img class="good-list-global-active" :src="item.picUrl" alt="" />
        <div class="good-list-global-info">
            <div class="good-list-global-title">{{ item.name }}</div>
            <div class="good-list-global-status" :style="item.status == 2 ? 'background:#DCDCDC' : ''">{{ statusArr[item.status] }}</div>
            <div class="good-list-global-tips">
                <div class="good-list-global-tip" v-if="item.isFree == 1">免费</div>
                <!-- <div class="good-list-global-tip">{{ options[item.type + 1].name }}</div> -->
                <div class="good-list-global-tip">{{ typeName }}</div>
            </div>
            <div class="good-list-global-time">
                <img src="@/assets/images/Frame-21.png" alt="" />
                <span>{{ item.startTime }}-{{ item.endTime }}</span>
            </div>
            <div class="good-list-global-address">
                <img src="@/assets/images/Frame-20.png" alt="" />
                <span>{{ item.address }}</span>
            </div>
        </div>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';
export default {
    // import 引入的组件需要注入到对象中才能使用
    name: 'goodListGlobal',
    components: {},
    data() {
        // 这里存放数据
        return {
            statusArr: ['即将开始', '进行中', '已结束'], //  0未开始，1进行中，2已结束
            options: [
                {
                    name: '全部',
                    value: '',
                },
                {
                    name: '演出',
                    value: 0,
                },
                {
                    name: '讲座',
                    value: 1,
                },
                {
                    name: '展览',
                    value: 2,
                },
                {
                    name: '会议',
                    value: 3,
                },
            ],
        };
    },
    props: {
        item: {},
    },
    // 方法集合
    methods: {},
    // 计算属性 类似于 data 概念
    computed: {
        typeName: function() {
            let type = this.options[this.item.type];
            if (type) {
                return this.options[this.item.type + 1].name;
            }
            return '';
        }
    },
    // 监控 data 中的数据变化
    watch: {},
    //过滤器
    filters: {},
    // 生命周期 - 创建之前
    beforeCreate() {},
    // 生命周期 - 创建完成（可以访问当前this 实例）
    created() {},
    // 生命周期 - 挂载之前
    beforeMount() {},
    // 生命周期 - 挂载完成（可以访问 DOM 元素）
    mounted() {},
    // 生命周期 - 更新之前
    beforeUpdate() {},
    // 生命周期 - 更新之后
    updated() {},
    // 生命周期 - 销毁之前
    beforeDestroy() {},
    // 生命周期 - 销毁完成
    destroyed() {},
    // 如果页面有 keep-alive 缓存功能,这个函数会触发
    //进入的时候触发
    activated() {},
    //离开的时候触发
    deactivated() {},
};
</script>

<style lang="less" scoped>
.good-list-global-item {
    display: flex;
    flex-direction: column;
    page-break-inside: avoid;
    width: 100%;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    overflow: hidden;
    .good-list-global-active {
        width: 100%;
        height: 210px;
    }

    .good-list-global-info {
        position: relative;
        padding: 12px;

        .good-list-global-title {
            font-weight: 700;
            font-size: 16px;
            line-height: 23px;
            color: #333333;
            width: 81%;
            .ellipsisLine(2);
        }

        .good-list-global-status {
            position: absolute;
            right: 12px;
            top: 12px;
            font-size: 12px;
            line-height: 17px;
            color: #ffffff;
            padding: 4px 6px;
            background: #f33f3e;
            border-radius: 2px;
        }

        .good-list-global-tips {
            display: flex;
            align-items: center;
            margin-top: 10px;

            .good-list-global-tip {
                padding: 4px 12px;
                background: rgba(243, 63, 62, 0.1);
                border-radius: 2px;
                flex: none;
                order: 0;
                flex-grow: 0;
                margin-right: 8px;
                font-size: 13px;
                line-height: 19px;
                color: #f33f3e;
            }
        }

        .good-list-global-time {
            display: flex;
            align-items: center;
            margin-top: 8px;

            img {
                width: 18px;
                height: 18px;
                margin-right: 6px;
            }

            span {
                font-size: 13px;
                line-height: 19px;
                color: #999999;
            }
        }

        .good-list-global-address {
            display: flex;
            align-items: center;
            margin-top: 8px;

            img {
                width: 18px;
                height: 18px;
                margin-right: 6px;
            }

            span {
                font-size: 13px;
                line-height: 19px;
                color: #999999;
            }
        }
    }
}
</style>
