//文化银行
export default [
    {
        path: '/bank',
        name: 'bank',
        component: () => import('@/views/bank/bank.vue'),
    },
    {
        path: '/bankList',
        name: 'bankList',
        component: () => import('@/views/bank/list.vue'),
    },
    {
        path: '/bankDetail',
        name: 'bankDetail',
        component: () => import('@/views/bank/detail.vue'),
    },
    {
        path: '/bankExchange',
        name: 'bankExchange',
        component: () => import('@/views/bank/exchange.vue'),
    },
];
