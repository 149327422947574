<template>
    <div class="sq-footer-box">
        <div class="sq-footer-logo">
            <span>友情链接</span>
            <div>
                <!--                <img :src="info.logo" alt="" />-->
            </div>
        </div>
        <div class="sq-footer-href">
            <div class="sq-footer-href-left">
                <div class="href">
                    <img :src="item" v-for="(item, index) in libraryOtherInfoList.linkPic" :key="index" />
                </div>
                <div class="footer-phone fp2">
                    <span>咨询电话</span>
                    {{ libraryOtherInfoList.telPhone }}
                </div>
                <div class="footer-phone">
                    <span>机构地址</span>
                    {{ libraryOtherInfoList.orgAddress }}
                </div>
            </div>
            <div class="sq-footer-href-right">
                <div></div>
                <div>
                    <img src="@/assets/images/31241.png" alt="" />
                    {{ libraryOtherInfoList.keepRecord }}
                </div>
                <div>{{ libraryOtherInfoList.copyright }}</div>
            </div>
        </div>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';
import { getLibraryOtherInfoList } from '@/api';

export default {
    // import 引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        // 这里存放数据
        return {
            time: '',
            libraryOtherInfoList: {},
            info: {},
        };
    },
    props: {},
    // 方法集合
    methods: {
        getLibraryOtherInfoList() {
            clearInterval(this.time);
            getLibraryOtherInfoList().then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    res.data.forEach((item) => {
                        item.linkPic = item.linkPic.split(',');
                    });
                    this.libraryOtherInfoList = res.data[0];
                    setTimeout(() => {
                        // console.log('场馆详情', this.$parent.info);
                        this.info = this.$parent.info;
                    }, 200);
                }
            });
        },
    },
    // 计算属性 类似于 data 概念
    computed: {},
    // 监控 data 中的数据变化
    watch: {},
    //过滤器
    filters: {},
    // 生命周期 - 创建之前
    beforeCreate() {},
    // 生命周期 - 创建完成（可以访问当前this 实例）
    created() {
        //获取父组件的data中的数据
        // console.log(this.$parent.libraryOtherInfoList);

        this.time = setInterval(() => {
            if (localStorage.getItem('id')) {
                this.getLibraryOtherInfoList();
            }
        }, 2000);
    },
    // 生命周期 - 挂载之前
    beforeMount() {},
    // 生命周期 - 挂载完成（可以访问 DOM 元素）
    mounted() {},
    // 生命周期 - 更新之前
    beforeUpdate() {},
    // 生命周期 - 更新之后
    updated() {},
    // 生命周期 - 销毁之前
    beforeDestroy() {},
    // 生命周期 - 销毁完成
    destroyed() {},
    // 如果页面有 keep-alive 缓存功能,这个函数会触发
    //进入的时候触发
    activated() {},
    //离开的时候触发
    deactivated() {},
};
</script>

<style lang="less" scoped>
.sq-footer-box {
    width: 1400px;
    margin: 0 auto;
    //padding-bottom: 25px;
    display: flex;
    height: 100%;
    flex-direction: column;
    .sq-footer-logo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        //padding-bottom: 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        div {
            padding-top: 25px;
            padding-bottom: 16px;
            width: 40%;
            display: flex;
            //border-left: 1px solid rgba(0, 0, 0, 0.08);
            justify-content: flex-end;
            img {
                width: 40px;
                height: 40px;
            }
        }

        span {
            font-weight: 700;
            font-size: 17px;
            color: rgba(0, 0, 0, 0.8);
            width: 60%;
        }
    }
    .sq-footer-href {
        flex: 1;
        display: flex;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
        &-left {
            width: 60%;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 16px 0 25px 0;

            .href {
                display: flex;
                flex-wrap: wrap;
                flex: 1;
                img {
                    width: 50px;
                    height: 50px;
                    margin-right: 16px;
                }
                //a {
                //    margin-right: 16px;
                //    font-size: 14px;
                //    color: rgba(0, 0, 0, 0.5);
                //    //去掉下划线
                //    text-decoration: none;
                //
                //    &:hover {
                //        color: #1890ff;
                //    }
                //}
            }
            .footer-phone {
                span {
                    font-weight: 700;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.8);
                    margin-right: 12px;
                }
            }
            .fp2 {
                margin-bottom: 12px;
            }
        }
        &-right {
            border-left: 1px solid rgba(0, 0, 0, 0.08);
            padding: 16px 0 25px 0;
            width: 40%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            div {
                margin-top: 12px;
                display: flex;
                align-items: center;
                img {
                    width: 19px;
                    height: 19px;
                    margin-right: 9px;
                }
            }
        }
    }
}
</style>
