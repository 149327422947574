export default [
    {
        path: '/excitingActivities',
        name: 'excitingActivities',
        component: () => import('@/views/activity/excitingActivities.vue'),
    },
    {
        path: '/venueReservation',
        name: 'venueReservation',
        component: () => import('@/views/activity/venueReservation.vue'),
    },
    {
        path: '/courseTraining',
        name: 'courseTraining',
        component: () => import('@/views/activity/courseTraining.vue'),
    },
    {
        path: '/courseTrainingDetail',
        name: 'courseTrainingDetail',
        component: () => import('@/views/activity/courseTrainingDetail.vue'),
    },
    {
        path: '/venueReservationDetail',
        name: 'venueReservationDetail',
        component: () => import('@/views/activity/venueReservationDetail.vue'),
    },
    {
        path: '/excitingActivitiesDetail',
        name: 'excitingActivitiesDetail',
        component: () => import('@/views/activity/excitingActivitiesDetail.vue'),
    },
];
