export default [
    {
        path: '/broadcast',
        name: 'broadcast',
        component: () => import('@/views/broadcast/broadcast.vue'),

        // '@/views/my/My.vue'
    },
    {
        path:'/video',
        name:'video',
        component: () => import('@/views/broadcast/video.vue'),
    }
]