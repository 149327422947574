export default [
    {
        path: '/volunteerIndex',
        name: 'volunteerIndex',
        component: () => import('@/views/volunteer/index.vue'),
    },
    {
        path: '/volunteerList',
        name: 'volunteerList',
        component: () => import('@/views/volunteer/list.vue'),
    },
    {
        path: '/volunteerDetail',
        name: 'volunteerDetail',
        component: () => import('@/views/volunteer/detail.vue'),
    },
];
