//总分管
export default [
    {
        path: '/inCharge',
        name: 'inCharge',
        component: () => import('@/views/inCharge/index.vue'),
    },
    {
        path: '/inCharge/detail',
        name: 'inChargeDetail',
        component: () => import('@/views/inCharge/detail.vue'),
    },
    {
        path: '/nonHeritage/inherit',
        name: 'nonHeritageInherit',
        component: () => import('@/views/nonHeritage/inherit.vue'),
    },
    {
        path: '/nonHeritage/activity',
        name: 'nonHeritageActivity',
        component: () => import('@/views/nonHeritage/activity.vue'),
    },
    {
        path: '/nonHeritage/detail',
        name: 'nonHeritageDetail',
        component: () => import('@/views/nonHeritage/detail.vue'),
    },
];
