export default [
    {
        path: 'my',
        name: 'my',
        component: () => import('@/views/my/My.vue'),
    },
    {
        // 订单详情
        path: '/OrderDetail',
        name: 'OrderDetail',
        component: () => import('@/views/my/OrderDetail.vue'),
    },
    {
        // 订单列表
        path: '/VenueDetail',
        name: 'VenueDetail',
        component: () => import('@/views/my/VenueDetail.vue'),
    },
    {
        // 订单列表
        path: '/ActivityDetail',
        name: 'ActivityDetail',
        component: () => import('@/views/my/ActivityDetail.vue'),
    },
    {
        // 我的消息
        path: '/News',
        name: 'News',
        component: () => import('@/views/my/News.vue'),
    },
    {
        // 消息详情
        path: '/NewsDetail',
        name: 'NewsDetail',
        component: () => import('@/views/my/NewsDetail.vue'),
    },
];
