// vr
export default [
    {
        path: '/vrList',
        name: 'vrList',
        component: () => import('@/views/vr/index.vue'),
    },
    {
        path: '/vrDetail',
        name: 'vrDetail',
        component: () => import('@/views/vr/detail.vue'),
    },
];
