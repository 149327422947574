//社群
export default [
    {
        path: '/communityList',
        name: 'communityList',
        component: () => import('@/views/community/list.vue'),
    },
    {
        path: '/communityDetail',
        name: 'communityDetail',
        component: () => import('@/views/community/detail.vue'),
    },
    {
        path: '/communityPostsDetail',
        name: 'communityPostsDetail',
        component: () => import('@/views/community/postsDetail.vue'),
    },
    {
        path: '/communityMember',
        name: 'communityMember',
        component: () => import('@/views/community/member.vue'),
    },
    {
        path: '/communityPersonnel',
        name: 'communityPersonnel',
        component: () => import('@/views/community/personnel.vue'),
    },
];
