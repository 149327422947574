//文化人才
export default [
    {
        path: '/culturalPersonnel',
        name: 'personnel',
        component: () => import('@/views/cultural/personnel.vue'),
    },
    {
        path: '/culturalFamily',
        name: 'family',
        component: () => import('@/views/cultural/family.vue'),
    },
    {
        path: '/culturalDetail',
        name: 'detail',
        component: () => import('@/views/cultural/detail.vue'),
    },
];
