import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/css/index.css';
import '@/assets/css/element-variables.scss';

//导入element
import ElementUI from 'element-ui';
// import '@/assets/css/index.css';

//自定义面包屑
import myBreadcrumb from '@/components/myBreadcrumb.vue';
import myFooter from '@/components/footer.vue';
import goodListGlobal from '@/components/goodListGlobal.vue';

//导入全局api
import hao from '@/api/hao.js';
Vue.prototype.$hao = hao;

//判断是不是生产环境
if (process.env.NODE_ENV === 'production') {
    console.log = function () {};
    console.error = function () {};
    console.warn = function () {};
}
Vue.use(ElementUI);
Vue.component('myBreadcrumb', myBreadcrumb);
Vue.component('myFooter', myFooter);
Vue.component('goodListGlobal', goodListGlobal);
ElementUI.Dialog.props.closeOnClickModal.default = false
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
    //添加到这里,这里的render-event和vue.config.js里面的renderAfterDocumentEvent配置名称一致
    mounted () {
        document.dispatchEvent(new Event('render-event'))
    }
}).$mount('#app');
