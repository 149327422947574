export default [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/home/home.vue'),
    },
    {
        path: '/informationDetails',
        name: 'informationDetails',
        component: () => import('@/views/home/informationDetails.vue'),
    },
    {
        path: '/informationList',
        name: 'informationList',
        component: () => import('@/views/home/informationList.vue'),
    },
    {
        path: '/consultationDynamicList',
        name: 'consultationDynamicList',
        component: () => import('@/views/home/consultationDynamicList.vue'),
    },
];
