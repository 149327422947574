import Vue from 'vue';
import VueRouter from 'vue-router';
const files = require.context('.', true, /\.js$/);
const modules = [];
//导入element-ui的message组件
import { Message } from 'element-ui';
// import { message } from 'element-ui';

files.keys().forEach((key) => {
    if (key === './index.js') return;
    modules.push(...files(key).default);
});

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: () => import('@/views/index/index.vue'),
        children: [...modules],
    },
];

const router = new VueRouter({
    mode:'history',
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});
//路由前置钩子
router.beforeEach((to, from, next) => {
    // console.log(to, from);
    next();
});

export default router;
