import { get, post, _delete } from '@/utils/request.js';

export default {
    // 获取场馆列表 / dev - api / system / library / list;
    getLibraryList(data) {
        return get({
            url: '/system/library/thirdparty/list',
            token: true,
            data,
        });
    },
    // 获取场馆详情 / dev - api / system / library / { id };
    getLibraryDetail(id) {
        return get({
            url: `/system/library/booking/${id}`,
            token: true,
        });
    },
    // 获取场馆场次列表 / dev - api / system / session / list;
    getLibrarySessionList(data) {
        return get({
            url: '/system/session/thirdparty/list',
            token: true,
            data,
        });
    },
    getSessionList(data) {
        return get({
            url: '/system/session/sessionList',
            token: true,
            data,
        });
    },
    // 预约场馆场次 / dev - api / system / booking;
    addBookingSession(data) {
        return post({
            url: '/system/booking',
            token: true,
            data,
        });
    },
    // 获取场馆场次预约详情 / dev - api / system / session / { id };
    getBookingDetail(id) {
        return get({
            url: `/system/booking/${id}`,
            token: true,
        });
    },
    // 取消预约场馆场次 / dev - api / system / booking / { id };
    delBookingSession(id) {
        return _delete({
            url: `/system/booking/${id}`,
            token: true,
        });
    },
    // 慕课学习列表 / dev - api / system / mooclearn / list;
    getMooclearnList(data) {
        return get({
            url: '/system/mooclearn/list',
            token: true,
            data,
        });
    },
    // 慕课学习详情 / dev - api / system / mooclearn / { id };
    getMooclearnDetail(id) {
        return get({
            url: `/system/mooclearn/${id}`,
            token: true,
        });
    },
    // 新增用户点赞收藏 / dev - api / system / praiseCollection;
    addPraiseCollection(data) {
        return post({
            url: '/system/praiseCollection',
            token: true,
            data,
        });
    },
    //退出社群system/community/exitCommunity?communityId=29
    exitCommunity(id) {
        return post({
            url: `/system/community/exitCommunity?communityId=${id}`,
            token: true,
        });
    },

    // 删除用户点赞收藏 / dev - api / system / praiseCollection / { id };
    delPraiseCollection(id) {
        return _delete({
            url: `/system/praiseCollection/${id}`,
            token: true,
        });
    },
    // 文明创作列表 / dev - api / system / civilized / list;
    getCivilizedList(data) {
        return get({
            url: '/system/creation/creationList',
            token: true,
            data,
        });
    },
    // 添加刘明创作
    addCreation(data) {
        return post({
            url: 'system/creation',
            data,
            token: true,
        });
    },
    // 添加用户心愿
    addUserdesire(data) {
        return post({
            url: 'system/userdesire',
            data,
            token: true,
        });
    },

    // 场馆详情 / dev - api / system / civilized / { id };
    getLibrarysDetail(id) {
        return get({
            url: 'system/library/' + id,
            token: true,
        });
    },
    // 文化人才列表
    getTalentFamilyList: (data) => {
        return get({
            url: 'system/talentFamily/list',
            data,
            token: true,
        });
    },
    // 文化人才详情
    getTalentFamilyDetail: (id) => {
        return get({
            url: 'system/talentFamily/' + id,
            token: true,
        });
    },
    // 查询社群列表 / dev - api / system / community / list;
    getCommunityList: (data) => {
        return get({
            url: 'system/community/communityList',
            data,
            token: true,
        });
    },
    getMyCommunitys(data) {
        return get({
            url: 'system/user/getCommunitys',
            token: true,
            data,
        });
    },
    //新增社群/dev-api/system/community
    addCommunity: (data) => {
        return post({
            url: 'system/community',
            data,
            token: true,
        });
    },
    //获取社群详情/dev-api/system/community/{id}
    getCommunityDetail: (id) => {
        return get({
            url: 'system/community/' + id,
            token: true,
        });
    },
    //获取社群帖子列表/dev-api/system/communityPosts/list

    getCommunityPostsList: (data) => {
        return get({
            url: 'system/communityPosts/list',
            data,
            token: true,
        });
    },
    //    获取用户帖子列表/dev-api/system/communityPosts/getPosts
    getMyPosts(data) {
        return get({
            url: 'system/communityPosts/getPosts',
            token: true,
            data,
        });
    },
    //新增社群帖子/dev-api/system/communityPosts
    addCommunityPosts: (data) => {
        return post({
            url: 'system/communityPosts',
            data,
            token: true,
        });
    },
    //获取社群帖子详情/dev-api/system/communityPosts/{id}
    getCommunityPostsDetail: (id) => {
        return get({
            url: 'system/communityPosts/' + id,
            token: true,
        });
    },
    //帖子评论列表/dev-api/system/communityComent/list
    getCommunityComentList: (data) => {
        return get({
            url: 'system/communityComent/list',
            data,
            token: true,
        });
    },
    //帖子评论/dev-api/system/communityComent
    addCommunityComent: (data) => {
        return post({
            url: 'system/communityComent',
            data,
            token: true,
        });
    },
    //加入社群/dev-api/system/community/join
    joinCommunity: (data) => {
        return post({
            url: 'system/community/join?communityId=' + data,
            token: true,
        });
    },
    // 文明创作列表 / dev - api / system / civilized / list;
    getCivilizedDetail: (id) => {
        return get({
            url: '/system/creation/list/' + id,
            token: true,
        });
    },
    // 我的作品列表
    getMyCreationList: (data) => {
        return get({
            url: 'system/user/getCreation',
            data,
            token: true,
        });
    },
    // 场馆预约列表
    getLibraryBookingList: (data) => {
        return get({
            url: 'system/booking/list',
            data,
            token: true,
        });
    },
    // 志愿者招募列表取第一条数据作为详情使用
    volunteerRecruitList: (data) => {
        return get({
            url: 'system/volunteerRecruit/list',
            data,
            token: true,
        });
    },
    //获取只愿招募system/recruit/list
    getVolunteerRecruitList: (data) => {
        return get({
            url: 'system/recruit/list',
            data,
            token: true,
        });
    },
    // 获取志愿者风采 system/service/list
    getVolunteerServiceList: (data) => {
        return get({
            url: 'system/service/list',
            data,
            token: true,
        });
    },

    // 获取志愿者风采详情
    getvolunteerServiceDetail: (id) => {
        return get({
            url: 'system/service/' + id,
            token: true,
        });
    },
    //获取志愿者风采system/volunteer/list 这个接口是获取志愿者
    getVolunteerList: (data) => {
        return get({
            url: 'system/volunteer/list',
            data,
            token: true,
        });
    },

    volunteerServiceList: (data) => {
        return get({
            url: 'system/volunteerService/list',
            data,
            token: true,
        });
    },
    // 志愿者招募详情
    volunteerServiceDetail: (id) => {
        return get({
            url: 'system/volunteerService/' + id,
            token: true,
        });
    },

    //TODO：我的收藏
    getMyCollect: (data) => {
        return get({
            url: 'system/user/getCollect',
            data,
            token: true,
        });
    },

    // 学习记录列表
    getMoocLearnRecordList: (data) => {
        return get({
            url: 'system/learnRecored/userLearns',
            data,
            token: true,
        });
    },

    //TODO：活动预约记录
    getActivityRecord: (data) => {
        return get({
            url: 'system/userActivity/list',
            data,
            token: true,
        });
    },
    //    查询社群用户列表
    getCommunityUsers: (data) => {
        return get({
            url: 'system/user/communityUsers',
            data,
            token: true,
        });
    },
    // 查询用户帖子列表
    getUserPosts: (data) => {
        return get({
            url: 'system/communityPosts/getPosts',
            data,
            token: true,
        });
    },
    // 推荐资源
    getRecommendResource: (data) => {
        return get({
            url: 'system/recommend/list',
            data,
            token: true,
        });
    },
    getDictDataByType: (data) => {
        return get({
            url: 'system/dict/data/type/' + data,
            method: 'GET',
            token: true,
        });
    },
	// 查询活动工作报告列表
	listUserActivityFile: (query) => {
	  return get({
	    url: 'system/userActivityFile/listApp',
	    method: 'get',
		token: true,
	    data: query
	  })
	},
	addEvaluation: (data) => {
	    return post({
	        url: 'system/evaluation',
	        data,
	        token: true,
	    });
	},
	listEvaluation: (data) => {
		data.reasonable = false;
		return get({
			url: 'system/evaluation/list',
			data,
			token: true
		})
	},
	getEquipmentList: (data) => {
		return get({
		    url: 'system/equipment/list',
		    data,
		    token: true,
		    loading: true,
		});
	},
	getEquipmentRecordList: (data) => {
		return get({
		    url: 'system/userEquipmentRecord/list',
		    data,
		    token: true,
		    loading: true,
		});
	},
	//查询场馆休息时间列表
	listLibraryRest: (data) => {
		return get({
			url: 'system/rest/list',
			data,
			token: true
		})
	}
};
