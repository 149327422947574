export default [
    {
        path: '/moocLearning',
        name: 'moocLearning',
        component: () => import('@/views/find/moocLearning.vue'),
    },
    {
        path: '/moocLearningDetail',
        name: 'moocLearningDetail',
        component: () => import('@/views/find/moocLearningDetail.vue'),
    },
    {
        path: '/civilizedCreation',
        name: 'civilizedCreation',
        component: () => import('@/views/find/civilizedCreation.vue'),
    },
    {
        path: '/civilizedCreationDetail',
        name: 'civilizedCreationDetail',
        component: () => import('@/views/find/civilizedCreationDetail.vue'),
    },
    {
        path: '/iWantToParticipate',
        name: 'iWantToParticipate',
        component: () => import('@/views/find/iWantToParticipate.vue'),
    },
];
